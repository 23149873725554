import React, { useState } from 'react';
import Header from '@components/Header';
import { graphql } from 'gatsby';
import Footer from '@components/Footer';
import Hero from '@components/theme/Hero';
import Page, { getPageElements } from '@components/Page';
import axios from 'axios';
import { kebabCase } from 'lodash';
import { Form, FormGroup, Input, Label, Button, Col } from 'reactstrap';
import { getTagsFromSessionStorage } from '@lib/searchQuery';

export default function PartnersTemplate({
  data: { page },
  pageContext: { language, refs, layouts, configuration }
}) {
  const { title, labelFirstName, labelLastName, labelEmail, labelCompanyName, labelCountry, labelProgram, labelProgramReferral, labelProgramReseller, buttonSubmit } = getPageElements(page);
  const isBrowser = typeof window !== 'undefined';
  const [busy, setBusy] = useState(false);
  let params = {};

  if (isBrowser) {
    const urlSearchParams = new URLSearchParams(window.location.search);
    params = Object.fromEntries(urlSearchParams.entries());
  }

  console.log({ params });

  const handleSubmit = event => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const body = Object.fromEntries(formData);
    setBusy(true);

    axios.post(
      'https://n8n.nospoilers.ai/webhook/b631d8d6-e52e-4dac-8cdf-8216b94c0fbd',
      JSON.stringify(Object.assign(body, getTagsFromSessionStorage())),
      { headers: { 'Content-Type': 'text/plain' }}
    )
      .then((response) => {
        setBusy(false);
        console.log(response);
        location.href = '/thank-you';
      })
      .catch((error) => {
        setBusy(false);
        console.error(error);
      });
  };

  return (
    <Page>
      <Hero title={title} divider={{ variant: 4, reflect: true }}>
        <Header
          language={language}
          refs={refs}
          layouts={layouts}
        />
      </Hero>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-7 col-lg-8 col-md-10">
            <Form onSubmit={handleSubmit}>
              <FormGroup row>
                <Label md="3" htmlFor="firstname">{labelFirstName}</Label>
                <Col md="9">
                  <Input type="text" placeholder={labelFirstName} id="firstname" name="firstname" defaultValue={params.firstname || ''} disabled={!!params.firstname} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md="3" htmlFor="lastname">{labelLastName}</Label>
                <Col md="9">
                  <Input type="text" placeholder={labelLastName} id="lastname" name="lastname" defaultValue={params.lastname || ''} disabled={!!params.lastname} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md="3" htmlFor="email">{labelEmail}</Label>
                <Col md="9">
                  <Input type="email" placeholder={labelEmail} id="email" name="email" defaultValue={params.email || ''} disabled={!!params.email} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md="3" htmlFor="company">{labelCompanyName}</Label>
                <Col md="9">
                  <Input type="text" placeholder={labelCompanyName} id="company" name="company" defaultValue={params.company || ''} disabled={!!params.company} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md="3" htmlFor="country">{labelCountry}</Label>
                <Col md="9">
                  <Input type="text" placeholder={labelCountry} id="country" name="country" defaultValue={params.country || ''} disabled={!!params.country} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md="3" htmlFor="partner_type">{labelProgram}</Label>
                <Col md="9">
                  <Input type="select" id="partner_type" name="partner_type" defaultValue={params.partner_type || 'Referral Partner'} disabled={!!params.partner_type}>
                    <option value="Referral Partner">{labelProgramReferral}</option>
                    <option value="Reseller Partner">{labelProgramReseller}</option>
                  </Input>
                </Col>
              </FormGroup>
              <FormGroup row className="justify-content-center py-3 mb-5">
                <Col>
                  <input type="hidden" name="hs_language" value={kebabCase(language.locale.toLowerCase())} />
                  <Button className="w-100" type="submit" color="primary" size="lg" disabled={busy}>{buttonSubmit}</Button>
                </Col>
              </FormGroup>
            </Form>
          </div>
        </div>
      </div>
      <Footer
        configuration={configuration}
        language={language}
        layouts={layouts}
      />
    </Page>
  );
}

export const query = graphql`
  query($id: String) {
    page: wpWebsite(id: { eq: $id }) {
      ...Page
    }
  }
`;